// Generated by Framer (24f4d74)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState } from "framer";

const cycleOrder = ["zyKJUidBo"];

const variantClassNames = {zyKJUidBo: "framer-v-yg138j"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "zyKJUidBo", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "zyKJUidBo", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QfR9e", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-yg138j", className)} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} layoutId={"zyKJUidBo"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Variant 1"} ref={ref}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QfR9e [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QfR9e * { box-sizing: border-box; }", ".framer-QfR9e .framer-yg138j { position: relative; width: 1026px; height: 217px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 217
 * @framerIntrinsicWidth 1026
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramervVKlT5bpt: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramervVKlT5bpt;

FramervVKlT5bpt.displayName = "Nav Trainin";

FramervVKlT5bpt.defaultProps = {width: 1026, height: 217};

addFonts(FramervVKlT5bpt, []);